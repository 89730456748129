var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Create various type inputs such as: "),_c('code',[_vm._v("text")]),_vm._v(", "),_c('code',[_vm._v("password")]),_vm._v(", "),_c('code',[_vm._v("number")]),_vm._v(", "),_c('code',[_vm._v("url")]),_vm._v(", "),_c('code',[_vm._v("email")]),_vm._v(", "),_c('code',[_vm._v("search")]),_vm._v(", "),_c('code',[_vm._v("date")]),_vm._v(" and more. You can use "),_c('code',[_vm._v("<input>")]),_vm._v(" tag. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('type')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('control-sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('contextual')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('contextual-example')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('formatter')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('datalist-support')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('debounce-support')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }